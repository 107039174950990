<template>
  <div>
    <the-breadcrumbs></the-breadcrumbs>
    <page-content page-title="Neuigkeit bearbeiten">
      <news-form
        :loading="loading"
        :news-item="newsItem"
        :form-submitted="saveNewsItem"
        mode="edit"
        @image-uploaded="addImage"
      ></news-form>
    </page-content>
  </div>
</template>

<script>
import Vue from "vue"
import TheBreadcrumbs from "@/components/TheBreadcrumbs"
import PageContent from "@/components/PageContent"
import NewsForm from "@/components/forms/NewsForm"
import NewsRepository from "@/repositories/news_repository.js"
import ApiErrorHandlerMixin from "@/mixins/api_error_handler.js"

export default {
  metaInfo: {
    title: "Neuigkeit bearbeiten"
  },
  components: {
    TheBreadcrumbs,
    PageContent,
    NewsForm
  },
  mixins: [ApiErrorHandlerMixin],
  data() {
    return {
      loading: true,
      newsItem: {
        content: ""
      }
    }
  },
  async created() {
    try {
      this.newsItem = await NewsRepository.get(this.$route.params.id)
      this.loading = false
    } catch (error) {
      this.loading = false
      this.handleApiError(error)
    }
  },
  methods: {
    async saveNewsItem(newsItem) {
      await NewsRepository.update(newsItem.id, newsItem)
      this.$router.push({ name: "NewsPage" })
    },
    async addImage({ newsItem, signed_id, command }) {
      try {
        newsItem.images.push(signed_id)
        let response = await NewsRepository.update(newsItem.id, newsItem)
        Vue.set(this.newsItem, "images", response.news_item.images)
        Vue.set(
          this.newsItem,
          "image_attachments",
          response.news_item.image_attachments
        )
        let attachment = this.newsItem.image_attachments.find(
          a => a.blob_signed_id === signed_id
        )
        command({
          src: `${process.env.VUE_APP_ROOT_API}/regions/image_proxy/${attachment.id}`
        })
      } catch (error) {
        this.handleApiError(error, "Fehler beim Hochladen vom Bild")
      }
    }
  }
}
</script>
